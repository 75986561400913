import { useState } from 'react';
import { useInterval } from 'react-use';
import { apirc } from '~/configs/apirc';
/**
 * @example
 *   //
 *   // 在 template 之中使用的話
 *   templateProps.layout.Providers = function Provider(props) {
 *   const LoginPage = useElementAsComponent(templateProps.layout.login)
 *
 *   const hasLogin = useIntervalCheckMe({
 *   permissionCheckFn() {
 *   return templateProps.permissions.pageview.check()
 *   },
 *   interval: 5 * 1000 * 60,
 *   })
 *
 *   if (hasLogin) {
 *   return <Fragment>{props.children}</Fragment>
 *   }
 *
 *   return <LoginPage />
 *   }
 */
export const useIntervalCheckMe = (options) => {
    const [hasLogin, setHasLogin] = useState(true);
    const intervalMs = options.interval ?? 5 * 1000 * 60;
    useInterval(() => {
        apirc.me.api.getMe.fetch().then(() => {
            setHasLogin(options.permissionCheckFn());
        });
    }, intervalMs);
    return hasLogin;
};
